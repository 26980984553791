import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as a, useState as n } from "react";
import "./Accordion-DtZRzadW.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import { B as d } from "./Button-BrPm3sL-.js";
import "./DropdownMenu-CjqQt0vp.js";
import { I as f } from "./Icon-C5a5ARPP.js";
import { I as r } from "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import "./Text-Bn-rHqca.js";
import "./Tooltip-Dy7rqQaX.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-fw2mabqr.js";
import "./Toast-Dk02TeEO.js";
import "./Toggle-Cn0qt44_.js";
const h = a((s, i) => {
  const [o, m] = n(!1), p = () => {
    m((e) => !e);
  };
  return /* @__PURE__ */ t.jsx(
    r,
    {
      ...s,
      ref: i,
      type: o ? "text" : "password",
      children: /* @__PURE__ */ t.jsx(r.Suffix, { children: /* @__PURE__ */ t.jsx(
        d,
        {
          size: "sm",
          variant: "ghost",
          onClick: p,
          children: /* @__PURE__ */ t.jsx(f, { width: 24, strokeWidth: 1.5, height: 24, name: o ? "EyeEmpty" : "EyeOff" })
        }
      ) })
    }
  );
});
h.displayName = "PasswordInput";
export {
  h as default
};
